<template lang="">
	<div
		class="bg-white w-100 text-center border-top py-3 hide-on-print"
	>
		<div class=" d-flex flex-wrap justify-content-center">
			<a
				v-if="$siteconf.policy_link"
				class="subtitle1 mx-5 under"
				:href="$siteconf.policy_link"
				target="_blank"
			>
				{{ $t(`Police`) }}
			</a>
			<a
				v-else-if="$siteconf.policy"
				class="subtitle1 mx-5 under"
				href="/privacy"
				target="_blank"
			>
				{{ $t(`Police`) }}
			</a>
			<a
				class="subtitle1 mx-5 under"
				:href="legalURL"
				target="_blank"
			>
				{{ $t(`Mentions`) }}
			</a>
			<a
				v-if="$siteconf.website"
				class="subtitle1 mx-5 under"
				:href="$siteconf.website"
				target="_blank"
			>
				{{ $siteconf.websiteTitle || $t(`clientWebsite`).replace('_', $siteconf.client || '') }}
			</a>
			<a
				href="https://data4job.com"
				class="subtitle1 mx-5 under"
				target="_blank"
			>{{ $t('devby') }} Data4Job</a>
		</div>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router";

export default {
	name: "FooterComponent",
	data() { return { ROUTES_NAME } },
	computed: {
		legalURL() { return this.$siteconf.legal || `/s/legal-${this.$root.$i18n.locale}.html` },
	}
}
</script>
<style lang="scss" scoped>
</style>
