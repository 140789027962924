<template lang="">
	<MatNavigation
		ref="menu"
		class="hide-on-print"
	>
		<ul class="navbar-nav m-3 d-block ">
			<AccountHeader />
			
			<li
				class="nav-item  py-2 "
				v-if="!$siteconf.hideHome"
			>
				<router-link
					class="body1me-5 under w-100 py-4 d-flex"
					:to="{ name: ROUTES_NAME.HOME }"
				>
					{{ $t(`accueil`) }}
				</router-link>
			</li>
			<li class="nav-item">
				<router-link
					class="body1 me-5 under w-100 py-4  d-flex"
					:to="{ name: ROUTES_NAME.OFFERS }"
				>
					{{ $t(`hOffers`) }}
				</router-link>
			</li>
		</ul>
	</MatNavigation>
	<header
		class="px-lg-5 py-1  bg-white flex-vertical-center position-fixed w-100 navbar navbar-expand-lg top-0 hide-on-print"
	>
		<div class="me-0  container-fluid">
			<router-link
				class=" me-2 "
				:to="{ name: ROUTES_NAME.HOME }"
			>
				<img
					v-if="$siteconf.icon"
					src="/c/icon.png"
				>
			</router-link> 
			<ul class="navbar-nav me-auto mb-2 mb-lg-0 d-none d-lg-flex">
				<li
					class="nav-item"
					v-if="!$siteconf.hideHome"
				>
					<router-link
						class="body1 p-4 me-1 under"
						:to="{ name: ROUTES_NAME.HOME }"
					>
						{{ $t(`accueil`) }}
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						class="body1 p-4 under"
						:to="{ name: ROUTES_NAME.OFFERS }"
					>
						{{ $t(`hOffers`) }}
					</router-link>
				</li>
			</ul>
			<div class="flex-max" />
			<span
				class="ms-3"
				v-if=" $route.name != ROUTES_NAME.HOME"
			>
				<router-link
					class="me-2 d-none d-lg-flex"
					:to="{name:ROUTES_NAME.POST, params:{offerId:'0', name: 'candidature_spontanee'}}"
					v-if="$siteconf.spontaneous_app"
				>
					<MatButton
						raised
						cta
						class="me-0 me-lg-3 border"
						style="box-shadow: none;"
						:label=" $t(`spontanee`) "
					/>
				</router-link>
			</span>
			<AccountHeader class="d-none d-lg-flex" />
			<span
				class="material-icons-outlined d-lg-none p-2 pointer"
				style="font-size: 36px;"
				:class="{'text-white':scrollpx<20}"
				@click="openMenu"
			>
				menu
			</span>
		</div>
	</header>
</template>

<script>
import { ROUTES_NAME } from "@/router";
import MatNavigation from "@/components/material/mat-navigation.vue";
import AccountHeader from "@/components/application/account-header.vue";

export default {
	name: "HeaderComponent",
	components: { MatNavigation, AccountHeader },
	props: {
		logo: {
			type: Boolean, default: () => false
		}
	},
	data() {
		return { ROUTES_NAME, scrollpx: 0 };
	},
	async mounted() {
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
		openMenu() {
			window.scrollTo({ top: 0, behavior: "smooth" })
			this.$refs.menu.open()
		}
	}
}
</script>

<style lang="scss" scoped>
header {
	height: 60px;

	@media (max-width: 991px) {
		background-color: transparent !important;
	}

	img {
		height: 50px;

		@media (max-width: 370px) {
			height: 40px;
		}
	}

	z-index: 1001;
}

.navbar-toggler {
	border: none !important;

	&:hover {
		border: none !important;
	}

	.navbar-toggler-icon {
		background-color: white !important;
	}
}
</style>
