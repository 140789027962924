import removeAccents from "./removeAccents";
import userData from "@/services/userData";
export function toURL(s, max, fallback) {
	if (!max) max = 35;
	s = removeAccents(s.toLowerCase())
		.replace(/[^a-z0-9]+/g, '-')
		.substring(0, max)
		.replace(/^-+/, '')
		.replace(/-+$/, '')
	return s || fallback || '_';
}

export function shortText(text, targetLength) {
	if (!text) return '';
	if (text.length <= targetLength * 1.1) return text;
	text = text.substring(0, Math.floor(targetLength * 1.2) + 20);
	let i = text.length;
	while (--i > targetLength * 0.8)
		if ('.;!?'.includes(text[i]))
			return text.substring(0, i + 1);
	i = targetLength + 1;
	while (--i > targetLength * 0.8)
		if (' \n\t,/()'.includes(text[i]))
			return text.substring(0, i) + '…';
	i = targetLength;
	while (++i < text.length)
		if (' \n\t,/()'.includes(text[i]))
			return text.substring(0, i) + '…';
	return text.substring(0, targetLength) + '…'
}

export function pageTitle(context, title) {
	return [
		shortText(title, 80),
		context.$siteconf.client,
		context.$t('CareerSite')
	].filter(t => t).join(' - ');
}

function isObject(object) {
	return object != null && typeof object === "object";
}

const Utils = {
	randomId() {
		const number = "00000" + Math.floor(Math.random() * 100000);
		return "id_" + number.substr(number.length - 5);
	},
	sleep(ms) {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	},
	scrollTo(parentId, targetId) {
		const targetBox = document.getElementById(targetId).getBoundingClientRect();
		const parentElement = document.getElementById(parentId);
		const parentBox = parentElement.getBoundingClientRect();
		parentElement.scrollTo({
			top: parentElement.scrollTop + (targetBox.y - parentBox.y),
			left: parentElement.scrollLeft + (targetBox.x - parentBox.x),
			behavior: "smooth",
		});
	},
	equals(object1, object2) {
		if (object1 == object2) return true;
		if (!object1) return false;
		if (!object2) return false;
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);
		if (keys1.length !== keys2.length) {
			return false;
		}
		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			const areObjects = isObject(val1) && isObject(val2);
			if (
				(areObjects && !this.equals(val1, val2)) ||
				(!areObjects && val1 != val2)
			) {
				return false;
			}
		}
		return true;
	},
	removeEmpty(obj) {
		for (let key in obj) {
			if (obj[key] == null) {
				delete obj[key];
			}
		}
		return obj;
	},
	toArray(obj) {
		if (!Array.isArray(obj)) {
			return [obj];
		}
		return obj;
	},
	isEmpty(obj) {
		return !obj || !Object.keys(obj).length;
	},
	isDescendant(parent, child) {
		if (parent == child) {
			return true;
		}
		var node = child.parentNode;
		while (node != null) {
			if (node == parent) {
				return true;
			}
			node = node.parentNode;
		}
		return false;
	},
	getFileInfo(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader);
			reader.onerror = error => reject(error);
		});
	},
	share(reseau, link) {
		let navUrl = ''
		if (reseau == "fb")
			navUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + link;

		if (reseau == "twitter")
			navUrl = 'https://twitter.com/intent/tweet?text=' + link;

		if (reseau == "linkedin")
			navUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=' + link;

		if (reseau == "instagram")
			navUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=' + link;


		if (navUrl) window.open(navUrl, "share offer", "width=200,height=200");
	},

	dataUrlToFile(dataUrl, name) {
		const arr = dataUrl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const extension = mime.split('/')[1];
		const filename = name || `${Date.now()}.${extension}`;
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	},
	changeTags(obj) {

		const newObj = {};

		// Iterate through the keys of the input object
		for (const key in obj) {
			let newValue = obj[key];
			if (typeof (obj[key]) == "string")
				newValue = this.replaceTags(newValue)

			// Assign the modified value to the new object
			newObj[key] = newValue;

		}

		// Return the new object with modified values
		return newObj;
	},
	replaceTags(text) {
		return text.replace(/<\s+h1/g,'<h3').replace(/<\s+h2/g,'<h4').replace(/<\s+h3/g,'<h5').replace(/<\s+h[4-5]/g,'<h6');
	},

	//////Regex

	hasUpperCase(string) {
		const hasUpperCase = /[A-Z]/;
		return hasUpperCase.test(string)
	},
	hasLowerCase(string) {
		const hasLowerCase = /[a-z]/;
		return hasLowerCase.test(string)
	},
	digitRegex(string) {
		const digitRegex = /\d/;
		return digitRegex.test(string)
	},
	specialCharRegex(string) {
		const specialCharRegex = /[!@#$%^&*()_+[\]{};:'",.<>?/\\|`~=-]/;
		return specialCharRegex.test(string)
	},

	verifPasswordRegex(string) {
		const verifPassword = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};:'",.<>?/\\|`~=-])[A-Za-z\d!@#$%^&*()_+[\]{};:'",.<>?/\\|`~=-]{8,}$/;
		return verifPassword.test(string)
	},
	verifEmail(string) {
		const verifEmail = /^[A-Za-z0-9._+'-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
		return verifEmail.test(string)
	},

	//get userData from back and upload localStorage

	updateUserStorage(user) {
		if (user) {
			if (user.personal) {
				let up = user.personal
				let pi = {}
				pi.firstName = up.firstname ? up.firstname : up.firstName
				pi.lastName = up.lastname ? up.lastname : up.lastName
				//console.log(new Date(up.birthday?.year, up.birthday?.month - 1, up.birthday?.day) );
				if (up.birthday) pi.birthday = this.isDate(up.birthday) ? up.birthday : new Date(up.birthday?.year, up.birthday?.month - 1, up.birthday?.day)
					? new Date(up.birthday?.year, up.birthday?.month - 1, up.birthday?.day) : null
				pi.address = up?.address ? up.address : up.location?.address ? up.location.address : ""
				pi.state = up?.state ? up.state : up.location?.state ? up.location.state : ""
				pi.city = up?.city ? up.city : up.location?.city ? up.location.city : ""
				pi.country = up?.country ? up.country : up.location?.country ? up.location?.country : "france"
				pi.cp = up?.cp ? up.cp : up.location?.cp ? up.location?.cp : ""
				pi.gender = !up.gender ? "undisclosed" : up.gender
				pi.phone = up.phone
				pi.email = up.email
				userData(null, 'contact', pi);
			}
			if (user.cv) {
				if (user.cv.experiences && user.cv.experiences.length > 0) {
					let nexArray = [];
					for (let i = 0; i < user.cv.experiences.length; i++) {
						let ue = user.cv.experiences[i]
						let nex = {}
						nex.title = ue.title
						nex.institution = ue.institution
						nex.still = !ue.end ? true : false
						nex.start = ue.from ? new Date(ue.from.year, ue.from.month - 1, ue.from.day) : null
						nex.end = ue.to ? new Date(ue.to.year, ue.to.month - 1, ue.to.day) : null
						nexArray.push(nex);

					}
					userData(null, 'exp', nexArray);
				}

				if (user.cv.educations && user.cv.educations.length > 0) {
					let nedArray = [];
					for (let i = 0; i < user.cv.educations.length; i++) {
						let ue = user.cv.educations[i]
						let ned = {}
						ned.title = ue.title ? ue.title : ""
						ned.level = ue.level
						ned.institution = ue.institution ? ue.institution : ""
						ned.location = ue.location ? ue.location : ""
						ned.still = ue.still ? ue.still : true
						ned.start = ue.start ? ue.start : new Date().getFullYear().toString()
						nedArray.push(ned);
					}
					userData(null, 'edu', nedArray);
				}

				let selectedLangs = [];
				let selectedSkills = [];
				let selectedTechs = [];
				if (user.cv.skills) {
					if (user.cv.skills.soft && user.cv.skills.soft.length > 0) {
						for (let i = 0; i < user.cv.skills.soft.length; i++) {
							let ue = user.cv.skills.soft[i]
							let ned = {}
							ned.data = ue.name
							ned.rating = ue.level ? ned.level : null
							selectedSkills.push(ned);

						}
					}
					if (user.cv.skills.technical && user.cv.skills.technical.length > 0) {
						for (let i = 0; i < user.cv.skills.technical.length; i++) {
							let ue = user.cv.skills.technical[i]
							let ned = {}
							ned.data = ue.name
							ned.rating = ue.level ? ned.level : null
							selectedTechs.push(ned);

						}
					}
					//this.$userData(null, 'edu', nedArray);
				}
				if (user.cv.languages && user.cv.languages.length > 0) {

					for (let i = 0; i < user.cv.languages.length; i++) {
						let ue = user.cv.languages[i]
						let ned = {}
						ned.data = ue.language
						ned.rating = ue.level ? ned.level : null
						selectedLangs.push(ned);
					}
				}
				let skills = {
					selectedTechs,
					selectedLangs,
					selectedSkills,
				};
				userData(null, 'skills', skills);
			}
			if (user.file) {
				let file = {}
				file.name = user.file.name
				file.data = "data:"+user.file.mimetype+";base64," + user.file.data
				file.__encoded = 'File'
				userData(null, 'file', file);
			}
			if (user.questionnaire && user.questionnaire.answers && user.questionnaire.answers.length > 0)
				userData(null, user.questionnaire.answers[0].id, user.questionnaire.answers[0].answers);
		}
	},
	isDate(object) {
		const parsedDate = new Date(Date.parse(object));
		return isNaN(parsedDate) ? undefined : parsedDate.toISOString().substring(0, 10);
	},


};
export default Utils;


class Color {
	constructor(r, g, b) {
		this.set(r, g, b);
	}

	toString() {
		return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`;
	}

	set(r, g, b) {
		this.r = this.clamp(r);
		this.g = this.clamp(g);
		this.b = this.clamp(b);
	}

	hueRotate(angle = 0) {
		angle = angle / 180 * Math.PI;
		const sin = Math.sin(angle);
		const cos = Math.cos(angle);

		this.multiply([
			0.213 + cos * 0.787 - sin * 0.213,
			0.715 - cos * 0.715 - sin * 0.715,
			0.072 - cos * 0.072 + sin * 0.928,
			0.213 - cos * 0.213 + sin * 0.143,
			0.715 + cos * 0.285 + sin * 0.140,
			0.072 - cos * 0.072 - sin * 0.283,
			0.213 - cos * 0.213 - sin * 0.787,
			0.715 - cos * 0.715 + sin * 0.715,
			0.072 + cos * 0.928 + sin * 0.072,
		]);
	}

	grayscale(value = 1) {
		this.multiply([
			0.2126 + 0.7874 * (1 - value),
			0.7152 - 0.7152 * (1 - value),
			0.0722 - 0.0722 * (1 - value),
			0.2126 - 0.2126 * (1 - value),
			0.7152 + 0.2848 * (1 - value),
			0.0722 - 0.0722 * (1 - value),
			0.2126 - 0.2126 * (1 - value),
			0.7152 - 0.7152 * (1 - value),
			0.0722 + 0.9278 * (1 - value),
		]);
	}

	sepia(value = 1) {
		this.multiply([
			0.393 + 0.607 * (1 - value),
			0.769 - 0.769 * (1 - value),
			0.189 - 0.189 * (1 - value),
			0.349 - 0.349 * (1 - value),
			0.686 + 0.314 * (1 - value),
			0.168 - 0.168 * (1 - value),
			0.272 - 0.272 * (1 - value),
			0.534 - 0.534 * (1 - value),
			0.131 + 0.869 * (1 - value),
		]);
	}

	saturate(value = 1) {
		this.multiply([
			0.213 + 0.787 * value,
			0.715 - 0.715 * value,
			0.072 - 0.072 * value,
			0.213 - 0.213 * value,
			0.715 + 0.285 * value,
			0.072 - 0.072 * value,
			0.213 - 0.213 * value,
			0.715 - 0.715 * value,
			0.072 + 0.928 * value,
		]);
	}

	multiply(matrix) {
		const newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
		const newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
		const newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
		this.r = newR;
		this.g = newG;
		this.b = newB;
	}

	brightness(value = 1) {
		this.linear(value);
	}
	contrast(value = 1) {
		this.linear(value, -(0.5 * value) + 0.5);
	}

	linear(slope = 1, intercept = 0) {
		this.r = this.clamp(this.r * slope + intercept * 255);
		this.g = this.clamp(this.g * slope + intercept * 255);
		this.b = this.clamp(this.b * slope + intercept * 255);
	}

	invert(value = 1) {
		this.r = this.clamp((value + this.r / 255 * (1 - 2 * value)) * 255);
		this.g = this.clamp((value + this.g / 255 * (1 - 2 * value)) * 255);
		this.b = this.clamp((value + this.b / 255 * (1 - 2 * value)) * 255);
	}

	hsl() {
		// Code taken from https://stackoverflow.com/a/9493060/2688027, licensed under CC BY-SA.
		const r = this.r / 255;
		const g = this.g / 255;
		const b = this.b / 255;
		const max = Math.max(r, g, b);
		const min = Math.min(r, g, b);
		let h, s, l = (max + min) / 2;

		if (max === min) {
			h = s = 0;
		} else {
			const d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;

				case g:
					h = (b - r) / d + 2;
					break;

				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}

		return {
			h: h * 100,
			s: s * 100,
			l: l * 100,
		};
	}

	clamp(value) {
		if (value > 255) {
			value = 255;
		} else if (value < 0) {
			value = 0;
		}
		return value;
	}
}

export class ColorSolver {
	constructor(color) {
		const rgb = typeof (color) === "string" ? hexToRgb(color) : color
		this.target = new Color(rgb[0], rgb[1], rgb[2]);
		this.targetHSL = this.target.hsl();
		this.reusedColor = new Color(0, 0, 0);
	}

	solve() {
		const result = this.solveNarrow(this.solveWide());
		return {
			values: result.values,
			loss: result.loss,
			filter: this.css(result.values),
		};
	}

	solveWide() {
		const A = 5;
		const c = 15;
		const a = [60, 180, 18000, 600, 1.2, 1.2];

		let best = { loss: Infinity };
		for (let i = 0; best.loss > 25 && i < 3; i++) {
			const initial = [50, 20, 3750, 50, 100, 100];
			const result = this.spsa(A, a, c, initial, 1000);
			if (result.loss < best.loss) {
				best = result;
			}
		}
		return best;
	}

	solveNarrow(wide) {
		const A = wide.loss;
		const c = 2;
		const A1 = A + 1;
		const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
		return this.spsa(A, a, c, wide.values, 500);
	}

	spsa(A, a, c, values, iters) {
		const alpha = 1;
		const gamma = 0.16666666666666666;

		let best = null;
		let bestLoss = Infinity;
		const deltas = new Array(6);
		const highArgs = new Array(6);
		const lowArgs = new Array(6);

		for (let k = 0; k < iters; k++) {
			const ck = c / Math.pow(k + 1, gamma);
			for (let i = 0; i < 6; i++) {
				deltas[i] = Math.random() > 0.5 ? 1 : -1;
				highArgs[i] = values[i] + ck * deltas[i];
				lowArgs[i] = values[i] - ck * deltas[i];
			}

			const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
			for (let i = 0; i < 6; i++) {
				const g = lossDiff / (2 * ck) * deltas[i];
				const ak = a[i] / Math.pow(A + k + 1, alpha);
				values[i] = fix(values[i] - ak * g, i);
			}

			const loss = this.loss(values);
			if (loss < bestLoss) {
				best = values.slice(0);
				bestLoss = loss;
			}
		}
		return { values: best, loss: bestLoss };

		function fix(value, idx) {
			let max = 100;
			if (idx === 2 /* saturate */) {
				max = 7500;
			} else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
				max = 200;
			}

			if (idx === 3 /* hue-rotate */) {
				if (value > max) {
					value %= max;
				} else if (value < 0) {
					value = max + value % max;
				}
			} else if (value < 0) {
				value = 0;
			} else if (value > max) {
				value = max;
			}
			return value;
		}
	}

	loss(filters) {
		// Argument is array of percentages.
		const color = this.reusedColor;
		color.set(0, 0, 0);

		color.invert(filters[0] / 100);
		color.sepia(filters[1] / 100);
		color.saturate(filters[2] / 100);
		color.hueRotate(filters[3] * 3.6);
		color.brightness(filters[4] / 100);
		color.contrast(filters[5] / 100);

		const colorHSL = color.hsl();
		return (
			Math.abs(color.r - this.target.r) +
			Math.abs(color.g - this.target.g) +
			Math.abs(color.b - this.target.b) +
			Math.abs(colorHSL.h - this.targetHSL.h) +
			Math.abs(colorHSL.s - this.targetHSL.s) +
			Math.abs(colorHSL.l - this.targetHSL.l)
		);
	}

	css(filters) {
		function fmt(idx, multiplier = 1) {
			return Math.round(filters[idx] * multiplier);
		}
		return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%)
		hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%)`;
	}
}

export function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => {
		return r + r + g + g + b + b;
	});

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? [
			parseInt(result[1], 16),
			parseInt(result[2], 16),
			parseInt(result[3], 16),
		]
		: null;
}

export const SearchIconMapping = {
	recent: "schedule",
	saved: "save",
	locations: "location_on",
	loc: "location_on",
	contracts: "description",
	sector: "work",
	specialty: "extension",
	minSalary: "payments",
	remotes: "home",
	experience: "work_history",
	education: "school",
	word: "spellcheck",
	//metiers:"sell",
	jobrnt: "sell",
}

export function titleCase(s) {
	return s.split(/\s+/)
		.filter(w => w)
		.map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
		.join(' ');
}

function shortLocation(gt, showCountry, showRegion, showDep, shortDep) {
	if (showCountry === undefined && gt.country && gt.country != 'france')
		showCountry = true;
	if (showRegion === undefined && gt.country && gt.country != 'france')
		showRegion = true;
	if (showDep === undefined)
		showDep = true;
	let out;
	if (gt.city) {
		if (gt.district) out = `${titleCase(gt.city)} ${gt.district}`;
		else {
			out = titleCase(gt.city);
			if (showDep && gt.dep) out += ` (${gt.dep})`;
		}
	} else if (gt.dep || gt.dep_txt) {
		if (gt.dep) {
			if (shortDep || !gt.dep_txt) out = gt.dep;
			else out = `${titleCase(gt.dep_txt)} (${gt.dep})`
		} else out = titleCase(gt.dep_txt);
	}
	if (out) {
		if (showRegion && gt.region) out += `, ${titleCase(gt.region)}`;
	} else if (gt.region) out = titleCase(gt.region);
	if (out) {
		if (showCountry && gt.country) out += `, ${titleCase(gt.country)}`;
	} else if (gt.country) out = titleCase(gt.country);
	return out;
}

function commonGt(gts) {
	if (!gts.length) return {};
	let common = {};
	const first = gts[0];
	if (first.country) {
		common.country = first.country;
		if (first.region) {
			common.region = first.region;
			if (first.dep || first.dep_txt) {
				common.dep = first.dep;
				common.dep_txt = first.dep_txt;
				if (first.city) {
					common.city = first.city;
					if (first.district) common.district = first.district;
				}
			}
		}
	}
	for (let i = 1; i < gts.length; ++i) {
		const gt = gts[i];
		if (gt.country && gt.country != common.country)
			return {}
		if (gt.region && common.region && gt.region != common.region)
			common = { country: common.country };
		else if ((gt.dep && common.dep) ? gt.dep != common.dep : (gt.dep_txt && common.dep_txt && gt.dep_txt != common.dep_txt))
			common = { country: common.country, region: common.region };
		else if (gt.city && common.city && gt.city != common.city) {
			delete common.city; delete common.district;
		}
		else if (gt.district && common.district && gt.district != common.district)
			delete common.district
	}
	return common;
}

export function shortLocations(gts) {
	if (!gts || !gts.length) return;
	if (gts.length == 1) {
		return shortLocation(gts[0]);
	} else {
		const common = commonGt(gts);
		if (common.city)
			return shortLocation(common) + ` (${gts.length} sites)`;
		if (!common.country) {
			let countries = {};
			for (let gt of gts) if (gt.country) countries[gt.country] = true;
			countries = Object.keys(countries).map(titleCase);
			switch (countries.length) {
				case 0: return;
				case 1: return `${countries[0]} (${gts.length} sites)`;
				case 2: return `${countries[0]}, ${countries[1]}`;
				default: return `${countries[0]}, ${countries[1]}, …+${countries.length - 2}`
			}
		}
		let out = shortLocation(gts[0], !common.country, !common.region, !common.dep, true);
		out += `, …+${gts.length - 1}`;
		const cs = shortLocation(common, undefined, undefined, undefined, true);
		return /^[0-9]{1,5}$/.test(cs) ? out + ` (${cs})` : out + ' - ' + cs;
	}
}

/*const fr = {country: 'france'};
const idf = {...fr, region: 'Île-de-France' };
const hds = { ...idf, dep: 92, dep_txt: 'Hauts-de-Seine' };
const montrouge = { ...hds, city: 'montrouge' };
const meudon = { ...hds, city: 'meudon' };
const paris13 = { ...idf, dep: 75, dep_txt: 'Paris', city: 'Paris', district: 13 }
const paris4 = { ...idf, dep: 75, dep_txt: 'Paris', city: 'Paris', district: 4 }
const la = {country: 'USA', region: 'California', city: 'Los Angeles' }
console.log(shortLocations([fr]))
console.log(shortLocations([idf]))
console.log(shortLocations([hds]))
console.log(shortLocations([montrouge]))
console.log(shortLocations([paris13]))
console.log(shortLocations([la]))
console.log(shortLocations([la, montrouge]))
console.log(shortLocations([paris13, montrouge]))
console.log(shortLocations([paris13, paris4]))
console.log(shortLocations([meudon, montrouge]))
console.log(shortLocations([meudon, montrouge, paris13, paris4]))*/