<template>
	<FormContainer
		v-bind="$attrs"
		class="candidate-container"
		:class="{ show: $route.name != ROUTES_NAME.OFFER_DETAILS }"
		:containerStyle="containerStyle"
	>
		<template #header>
			<Header
				:offer="offer"
				@reset="reset"
			/>
		</template>
		<div class="steps">
			<Stepper
				:config="config"
				:step="step"
				:form="form"
			/>
		</div>
		<template #graph>
			<Graph
				:config="config"
				:step="step"
				:oldStep="oldStep"
				:showtext="isEmpty"
			/>
		</template>
		<template
			v-for="(c, index) in config"
			:key="c"
		>
			<div
				class="position-absolute app-shadow w-100 candidate-form-content mt-3 mt-lg-0"
				:class="{
					'mt-5 validate overflow-hidden ': $route.params.step == 'done'
					, 'overflow-hidden ': $route.params.step == 'mode'
				}"
				v-if="index == step"
			>
				<div
					class=" px-3 px-lg-5  flex-max position-relative "
					:class="[{ 'overflow-auto': $route.params.step != 'done' }, [$route.params.step != 'form' ? 'pt-5 pb-3 ' : 'pt-3 pb-2']]"
				>
					<div class="position-relative dynamic-form">
						<Transition
							:name="step >= oldStep ? 'slide-up' : 'slide-down'"
							mode="out-in"
						>
							<div>
								<component
									@update-candid-data="updateCandidData()"
									:is="c.formComponent"
									v-bind="c.props"
								/>
							</div>
						</Transition>
					</div>
				</div>
				<div
					v-if="next || prev"
					class="pb-5 d-flex flex-column "
				>
					<div class="d-flex flex-row justify-content-center">
						<MatButton
							outlined
							v-if="prev"
							:label="prev.label || prev"
							@click="prev.action ? prev.action() : step--"
							class="me-4"
						/>

						<MatButton
							:class="{ 'd-none': $route.params.step == 'done' && (!$siteconf.accounts || isAuthenticated) }"
							raised
							v-if="next"
							:label="next.label || next"
							@click="next.action ? next.action() : step++"
						/>
					</div>
				</div>
			</div>
		</template>
	</FormContainer>
</template>
<script>
import { ROUTES_NAME } from "@/router";
import PersonalInfo from "@/components/application/forms/personal-info-form.vue";
import Experience, {
	defaultExperience,
} from "@/components/application/forms/experience-form.vue";
import FinalResult from "@/components/application/forms/final-result-form.vue";
import Education, {
	defaultEducation,
} from "@/components/application/forms/education-form.vue";
import Skills from "@/components/application/forms/skills-form.vue";
import Dynamic from "@/components/application/forms/dynamic-form.vue";
//import Validation from "@/components/application/forms/validation-form.vue";
import CandidMode from "@/components/application/forms/candid-mode-form";
import CandidPost from "@/components/application/forms/candid-post-form";
import UploadResume from "@/components/application/forms/upload-resume-form.vue";
//import JobOffer from "@/components/application/forms/job-offer-form.vue";
import Login from "@/components/application/forms/login-form.vue";
//import FinalSignin from "@/components/application/forms/final-signin-form.vue";

import PersonalInfoGraph from "@/components/application/forms/graphics/personal-info-graph.vue";
import ExperienceGraph from "@/components/application/forms/graphics/experience-graph.vue";
import EducationGraph from "@/components/application/forms/graphics/education-graph.vue";
import SkillsGraph from "@/components/application/forms/graphics/skills-graph.vue";
import AdvancedFormGraph from "@/components/application/forms/graphics/advanced-form-graph.vue";
import UploadResumeGraph from "@/components/application/forms/graphics/upload-resume-graph.vue";
//import ValidationGraph from "@/components/application/forms/graphics/validation-graph.vue";
import CandidModeGraph from "@/components/application/forms/graphics/candid-mode-graph.vue";
import CandidPostGraph from "@/components/application/forms/graphics/candid-post-graph.vue";
//import JobOfferGraph from "@/components/application/forms/graphics/job-offer-graph.vue";
import LoginGraph from "@/components/application/forms/graphics/login-graph.vue";
import { shallowRef } from "@vue/reactivity";
import { EducationLevel, Contract } from "@/utils/constants";
import FormContainer, {
	CONTAINER_STYLE,
} from "@/components/application/form-container.vue";
import Header from '@/components/application/candidate-header.vue';
import Stepper from '@/components/application/candidate-stepper.vue';
import Graph from '@/components/application/candidate-graph.vue';

const contactInfo = {
	phone: "",
	email: "",
	accept: false
};

let personalInfo = {
	firstName: "",
	lastName: "",
	birthday: null,
	email: "",
	phone: "",
	address: "",
	state: "",
	country: "france",
	cp: "",
	picture: "",
	gender: "undisclosed"
};

const advanced = {
	handicap: null,
	relocate: null,
	contracts: [],
	study: 0,
	studyField: null,
	experience: 0,
	refrence: null,
	graduation: null,
	availabilityDate: null,
	experienceDescription: null,
};

const skills = {
	selectedTechs: [],
	selectedLangs: [],
	selectedSkills: [],
};

let info = {
	personalInfo,
	experience: [JSON.parse(JSON.stringify(defaultExperience))],
	education: [JSON.parse(JSON.stringify(defaultEducation))],
	skills,
	advanced,
};

const autoInfo = {
	personalInfo,
	file: null,
	advanced
};

/*const finalSignin = {
	jobOffer: {
		availablity: "",
		title: "",
		location: "",
		contract: "",
	},
	skills,
};*/
let dynamicForm = {};

let signupData = {
	email: "",
	password: "",
	rgpd_consent: false
};
let loginData = {
	email: "",
	password: "",
}
export default {
	components: {
		FormContainer,
		Header, Stepper, Graph
	},
	emits: ['updateCandidData'],
	props: {
		offer: {
			type: Object,
			default: null,
		},
		title: {
			type: String,
			default: "",
		}
	},
	computed: {
		isAuthenticated() {
			return this.$store.state.token;
		}
	},
	data() {
		return {
			contactInfosend: {},
			dynForm: [],
			isEmpty: true,
			whichMode: null,
			shortid: null,
			job_title: "",
			candidatId: null,
			sk_index: null,
			edu_index: null,
			exp_index: null,
			formAuto_index: null,
			formManu_index: null,
			form: false,
			Contract,
			EducationLevel,
			CONTAINER_STYLE,
			prev: "",
			next: "",
			step: null,
			oldStep: 0,
			transition: "slide-up",
			containerStyle: null,
			soumettre: false,
			signinConfig: [
				{
					formComponent: shallowRef(Login),
					graphComponent: shallowRef(LoginGraph),
					props: {
						onSigninClick: () => this.action('signin'),
						datalogin: loginData,
						signinError: false
					},
				},
			],
			signupConfig: [
				{
					formComponent: shallowRef(Login),
					graphComponent: shallowRef(LoginGraph),
					props: {
						onSignupClick: () => this.action('signup'),
						data: signupData,
						signinError: false
					},
				},
			],
			candidModeConfig: [
				{
					step: "adresse",
					formComponent: shallowRef(CandidPost),
					graphComponent: shallowRef(CandidPostGraph),
					props: {
						onNextClick: (type) => this.verifCandidat(type),
						data: contactInfo,
					},
				},
				{
					step: "mode",
					formComponent: shallowRef(CandidMode),
					props: {
						data: {
							autoResumeClick: () => {
								this.step = null;
								this.config = this.autoModeConfig;
							},
							manualResumeClick: () => {
								this.step = null;
								this.config = this.manualModeConfig;
							},
							// connectClick: () => {
							// 	this.step = null;
							// 	this.config = this.signupConfig;
							// },
						},
					},
					graphComponent: shallowRef(CandidModeGraph),
				},
			],
			config: [],
			autoModeConfig: [
				{
					step: "upload",
					formComponent: shallowRef(UploadResume),
					graphComponent: shallowRef(UploadResumeGraph),
					props: {
						data: autoInfo.file,
						candidatId:this.$pref.candidatId,
						onNextClick: () => this.step++,
						onPrevClick: () => this.uploadPrev(),
					},
					name: "1CV",
				},
				{
					step: "info",
					formComponent: shallowRef(PersonalInfo),
					props: {
						form: true,
						data: autoInfo.personalInfo,
						contactData: contactInfo,
						onNextClick: () => this.form ? this.soumettreAuto(false) : this.soumettreAuto(true),
						onPrevClick: () => this.step--
					},
					graphComponent: shallowRef(PersonalInfoGraph),
					name: "2Informations_personelles",
				},
				{
					step: "form",
					formComponent: shallowRef(Dynamic),
					props: {
						data: {},
						formAns: dynamicForm,
						onNextClick: (fromdata) => this.soumettreAuto(true, fromdata),
						onPrevClick: () => this.step--
					},
					graphComponent: shallowRef(AdvancedFormGraph),

					name: "3Formulaire",
				},
				{
					step: "done",
					formComponent: shallowRef(FinalResult),
					props: {
						title: this.offer.job_title,
					},
					prev: {
						label: "Revenir au résultat de recherche",
						action: () =>
							this.reset("done")
					},
					containerStyle: CONTAINER_STYLE.CENTRED_STYLE,
				},
			],
			//manual mode with form
			manualModeConfig: [
				{
					step: "info",
					formComponent: shallowRef(PersonalInfo),
					props: {
						data: info.personalInfo,
						onNextClick: () => this.step++,
						contactData: contactInfo,
					},
					graphComponent: shallowRef(PersonalInfoGraph),
					name: "1Informations_personelles",
				},
				{
					step: "experience",
					formComponent: shallowRef(Experience),
					props: {
						data: info.experience,
						onNextClick: () => this.step++,
						onPrevClick: () => this.step--,
					},
					graphComponent: shallowRef(ExperienceGraph),
					name: "2Experience",
				},
				{
					step: "education",
					formComponent: shallowRef(Education),
					props: {
						data: info.education,
						form: this.form,
						onNextClick: () => {
							if (this.$siteconf.lightCv && !this.form) {
								this.soumettreManuelle(true);
								this.step = 5;
							}
							else if (this.$siteconf.lightCv && this.form) {
								this.soumettreManuelle(false);
								this.step = 4;
							}
							else if (!this.$siteconf.lightCv) {
								this.step++;
							}
						},
						onPrevClick: () => this.step--,
					},

					graphComponent: shallowRef(EducationGraph),
					name: "3Formation",
				},
				{
					step: "skills",
					formComponent: shallowRef(Skills),
					props: {
						data: info.skills,
						form: this.form,
						onNextClick: () => {
							if (this.form) {
								this.soumettreManuelle(false); this.step++
							} else {
								this.soumettreManuelle(true)
								this.step = 5
							}
						},
						onPrevClick: () => this.step--,
					},
					graphComponent: shallowRef(SkillsGraph),
					name: "4Compétences",
				},
				{
					step: "form",
					formComponent: shallowRef(Dynamic),
					props: {
						data: {},
						formAns: dynamicForm,
						onNextClick: (formAnswers) => { this.soumettreManuelle(true, formAnswers); this.step++ },
						onPrevClick: () => { this.$siteconf.lightCv ? this.step = this.step - 2 : this.step-- }
					},
					graphComponent: shallowRef(AdvancedFormGraph),
					name: this.$siteconf.lightCv ? "4Formulaire" : "5Formulaire",

				},
				{
					step: "done",
					formComponent: shallowRef(FinalResult),
					props: {
						title: this.offer.job_title,
					},
					prev: {
						label: this.$toMove?.search ? this.$t('done') : this.$t('doneTous'),
						action: () =>
							this.reset("done")
					},
					next: {
						label: this.$t('inscrit'),
						action: () =>
							this.$router.push({ name: ROUTES_NAME.LOGIN, params: { config: 'signup' } })
					},
					containerStyle: CONTAINER_STYLE.CENTRED_STYLE,
				},
			],
			cooptationlModeConfig: [
				{
					step: "form",
					formComponent: shallowRef(Dynamic),
					props: {
						data: {},
						formAns: dynamicForm,
						onNextClick: (cooptationFormAns) => { this.saveCooptationAns(cooptationFormAns); this.step++ },
						onPrevClick: () => { }
					},
					graphComponent: shallowRef(AdvancedFormGraph),
					name: "1Formulaire",
					containerStyle: this.$siteconf?.hideImages?.application ? CONTAINER_STYLE.CENTRED_STYLE : null,

				},
				{
					step: "adresse",
					formComponent: shallowRef(CandidPost),
					graphComponent: shallowRef(CandidPostGraph),
					props: {
						onNextClick: () => {
							this.getCandidId(); this.step++
						},
						onPrevClick: () => { this.step-- },

						data: contactInfo,
					},
					name: "1Informations_candid",
					containerStyle: this.$siteconf?.hideImages?.application ? CONTAINER_STYLE.CENTRED_STYLE : null,

				}
				, {
					step: "info",
					formComponent: shallowRef(PersonalInfo),
					props: {
						data: info.personalInfo,
						onNextClick: () => { this.step++ },
						onPrevClick: () => { this.$siteconf.cooptation ? this.step-- : '' },
						contactData: contactInfo,
					},
					graphComponent: shallowRef(PersonalInfoGraph),
					name: "3Informations_personelles",
					containerStyle: this.$siteconf?.hideImages?.application ? CONTAINER_STYLE.CENTRED_STYLE : null,

				}, {
					step: "upload",
					formComponent: shallowRef(UploadResume),
					graphComponent: shallowRef(UploadResumeGraph),
					props: {
						data: autoInfo.file,
						candidatId: this.$pref.candidatId,
						onNextClick: () => { this.sendCooptation() },
						onPrevClick: () => { this.$siteconf.cooptation ? this.step-- : '' },
					},
					name: "4CV",
					containerStyle: this.$siteconf?.hideImages?.application ? CONTAINER_STYLE.CENTRED_STYLE : null,
				},
				{
					step: "done",
					formComponent: shallowRef(FinalResult),
					props: {
						title: this.offer.job_title,
					},
					prev: {
						label: this.$toMove?.search ? this.$t('done') : this.$t('doneTous'),
						action: () =>
							this.reset("done")
					},
					next: {
						label: this.$t('inscrit'),
						action: () =>
							this.$router.push({ name: ROUTES_NAME.LOGIN, params: { config: 'signup' } })
					},
					containerStyle: CONTAINER_STYLE.CENTRED_STYLE,
				},
			],
			ROUTES_NAME,
		};
	},
	watch: {
		step: {
			handler(newVal, oldVal) {
				if (oldVal == newVal) return;
				if (this.step == null) this.step = 0;
				if (!this.config || this.config.length == 0)
					this.selectConfig();
				this.update();
				setTimeout(() => {
					this.oldStep = newVal;
				}, 100);
			},
		},
		$route() {
			signupData.password = ""
			signupData.rgpd_consent = false
			loginData.password = ""
			this.signinConfig[0].props.signinError = false
			this.signupConfig[0].props.signupError = false
			//if(this.$siteconf.cooptation && !this.$route?.params ) {contactInfo.email="" ; contactInfo.phone=""}
			this.selectConfig();
			this.updateCandidData()
			if (this.$route.params.offerId == 0) {
				this.shortid = 0
			}
		},
	},
	methods: {
		update() {
			const config = this.config[this.step];
			(this.prev = config?.prev), (this.next = config?.next);
			this.containerStyle = config?.containerStyle;
			const entry = Object.entries(this.configs).find(
				([, config]) => config == this.config
			);
			const newConfigName = entry ? entry[0] : null;
			this.$router.push({
				name: this.$route.name,
				params: {
					...this.$route.params,
					config: newConfigName,
					step: config.step,
				},
			});
		},
		selectConfig() {
			let newStep = 0;
			if (
				![ROUTES_NAME.SIGNUP_INFO, ROUTES_NAME.POST].includes(
					this.$route.name
				)
			)
				return;

			if (this.$route.name == ROUTES_NAME.POST) {
				if (this.$route?.params?.config == "resetpassword" || this.$route?.params?.config == "signin") this.config = this.signinConfig
				else if (this.$route?.params?.config == "signup") this.config = this.signupConfig
				else if (this.$siteconf.cooptation && (!this.$route?.params?.config || this.$route?.params?.config == "form"
					|| this.$route?.params?.config == "adresse" || this.$route?.params?.config == "info" || this.$route?.params?.config == "upload"
					|| this.$route?.params?.config == "done")) {
					this.config = this.cooptationlModeConfig

				}
				else {
					this.configName = this.$route.params.config || "mode";
					this.config = this.configs[this.configName];
				}
			}

			newStep = this.config.findIndex((c) => c.step == this.$route.params.step || c.step == this.$route.params?.config);
			if (newStep == -1) newStep = 0
			if (newStep != this.step) {
				this.step = newStep;
				this.stepName = this.config[this.step].step;
			}



		},
		async soumettreAuto(soumettre, formAns) {
			this.updateCandidData();
			if(!this.candidatId) this.candidatId = this.$pref.candidatId ? this.$pref.candidatId :  this.getCandidId()
			const forms = [];

			let email = contactInfo.email
			let phone = contactInfo.phone
			if (!phone || !email) {
				let getpersonalInfo = this.$userData.load('contact', null);
				if (!phone) phone = getpersonalInfo.phone ? getpersonalInfo.phone : ""
				if (!email) email = getpersonalInfo.email ? getpersonalInfo.email : ""
			}

			const obj = {}
			obj.contact = {}
			if (email) obj.contact.email = email
			if (phone) obj.contact.phone = phone

			////info personnel
			const candidate = this.prepareCandidateInfo()
			const formData = new FormData();
			if (soumettre) {
				if (this.autoModeConfig[this.formAuto_index].props.formAns && this.autoModeConfig[this.formAuto_index].props.data.fields) {

					const formAnswersProps = this.autoModeConfig[this.formAuto_index].props;
					if (formAnswersProps.formAns && formAnswersProps.data.fields && formAns) {
						let uniqFile = {};
						const formAn = formAnswersProps.data.fields.map((field, index) => {
							const answer = formAns[index];
							if (!answer) return undefined;
							if (field.type === "date") return this.$utils.isDate(answer);
							if (field.type === "attachment") {
								if (!uniqFile[answer.hash]) {
									formData.append(answer.hash, answer.file);
									uniqFile[answer.hash] = true;
								}
								return answer.hash;
							}
							if (field.type === "cv") return undefined;
							return answer;
						});
						if (formAn.length > 0) {
							forms.push({
								name: formAnswersProps.data.name,
								id: this.manualModeConfig[this.formManu_index].props.data.id,
								answers: formAn
							});
						}
					}
				}
			}
			//if (obj.email) formData.append('email', obj.email);
			//if (obj.phone) formData.append('phone', obj.phone);
			if (soumettre) formData.append('finish', true);
			formData.append('info', JSON.stringify(candidate));
			if (obj.contact) formData.append('contact', JSON.stringify(obj.contact));
			if (forms.length && soumettre) formData.append('answers', JSON.stringify(forms));
			if (this.candidatId) formData.append('id', this.candidatId);
			try {
				if (this.shortid == null || !autoInfo.file && !obj)
					throw new Error('missing data to submit');
				let formAnswers = await this.$service.candidature.submit(this.shortid, formData)
				if (formAnswers && formAnswers.forms?.length) {
					formAnswers.forms[0].fields.forEach((el, index) => {
						el.key = index
					})
					this.autoModeConfig[this.formAuto_index].props.data = formAnswers.forms[0]
					this.manualModeConfig[this.formManu_index].props.data = formAnswers.forms[0]
				}

				if (formAnswers && formAnswers.answers?.length) {
					let cc = []
					if (this.autoModeConfig[this.formAuto_index].props && formAnswers.answers) {
						formAnswers.answers[0].answers.forEach((el, index) => {
							cc[index] = el
						})
					}
					if (cc) this.autoModeConfig[this.formAuto_index].props.formAns = cc
					this.updateCandidData();
				}
				this.form ? this.step++ : this.step = this.step + 2
				this.autoModeConfig[3].props.code = 200
			} catch (e) {
				if (!e.response || e.response.status !== 200) {
					this.step = 3
					this.autoModeConfig[3].props.code = e.response.status
				}
			}
		},
		async soumettreManuelle(soumettre, _formAnswers) {
			this.updateCandidData()
			if(!this.candidatId) this.candidatId = this.$pref.candidatId ? this.$pref.candidatId :  this.getCandidId()
			const getYearMonthDay = function (date) {
				return { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }
			}
			let email = contactInfo.email
			let phone = contactInfo.phone
			if (!phone || !email) {
				let getpersonalInfo = this.$userData.load('contact', null);
				if (!phone) phone = getpersonalInfo.phone ? getpersonalInfo.phone : ""
				if (!email) email = getpersonalInfo.email ? getpersonalInfo.email : ""
			}

			//info personnel
			let minfo = this.prepareCandidateInfo()

			//cv
			let mcv = {}
			mcv.title = this.offer.job_title || "Candidature spontanée"

			let exist = this.manualModeConfig[this.exp_index].props.data.some(el => el.title && el.institution);

			if (exist) mcv.experiences = this.manualModeConfig[this.exp_index].props.data;

			mcv.educations = this.manualModeConfig[this.edu_index].props.data
			if (mcv.experiences) {
				mcv.experiences = mcv.experiences.map(el => {
					const experience = { ...el };
					if (experience.start) {
						experience.from = getYearMonthDay(new Date(experience.start));
						delete experience.start;
					}
					if (experience.still) {
						delete experience.end;
						delete experience.still;
					} else {
						experience.to = getYearMonthDay(new Date(experience.end));
						delete experience.end;
					}
					return experience;
				});
			}
			if (mcv.educations) {
				mcv.educations = mcv.educations.map(el => {
					const education = { ...el };
					if (education.start) {
						education.from = getYearMonthDay(new Date(education.start));
						delete education.start;
					}
					if (education.still) {
						delete education.still;
					}
					return education;
				});
			}

			// Extract languages, soft skills, and technical skills
			const selectedLangs = this.manualModeConfig[this.sk_index].props.data.selectedLangs || [];
			const selectedSkills = this.manualModeConfig[this.sk_index].props.data.selectedSkills || [];
			const selectedTechs = this.manualModeConfig[this.sk_index].props.data.selectedTechs || [];
			if ((selectedSkills && selectedSkills.length > 0) || (selectedTechs && selectedTechs.length > 0)) mcv.skills = {}
			// Process languages
			if (selectedLangs.length > 0) mcv.languages = selectedLangs.map(lang => ({
				language: lang.data,
				level: lang.rating || undefined
			}));

			// Process soft skills
			if (selectedSkills.length > 0) mcv.skills.soft = selectedSkills.map(skill => ({
				name: skill.data,
				level: skill.rating || undefined
			}));

			// Process technical skills
			if (selectedTechs.length > 0) mcv.skills.technical = selectedTechs.map(tech => ({
				name: tech.data,
				level: tech.rating || undefined
			}));


			//dynamic forms
			let forms = [];
			const formData = new FormData();
			if (soumettre) {
				const formFields = this.manualModeConfig[this.formManu_index].props.data.fields;
				const formAnswers = _formAnswers || this.manualModeConfig[this.formManu_index].props.formAns;
				if (formAnswers && formFields) {
					const uniqFile = {};
					const objAns = {
						name: this.manualModeConfig[this.formManu_index].props.data.name,
						id: this.manualModeConfig[this.formManu_index].props.data.id,
						answers: formFields.map((field, index) => {
							const answer = formAnswers[index];
							if (!answer) return undefined;
							if (field.type === "date") return this.$utils.isDate(answer);
							if (field.type === "attachment") {
								if (!uniqFile[answer.hash]) {
									formData.append(answer.hash, answer.file);
									uniqFile[answer.hash] = true;
								}
								return answer.hash;
							}
							if (field.type === "cv") return undefined;
							return answer;
						})
					};
					forms.push(objAns);
				}
			}
			//object to send
			let obj = {}
			obj.contact = {}
			if (email) obj.contact.email = email
			if (phone) obj.contact.phone = phone
			if (minfo) obj.info = minfo
			if (mcv) obj.cv = mcv
			if (!this.form) obj.finish = true
			if (this.candidatId) obj.id = this.candidatId
			if (forms.length > 0 && soumettre) obj.answers = forms
			try {
				if (!soumettre && this.form) {
					let formAnswers = await this.$service.candidature.submitManual(this.shortid, obj)
					if (formAnswers && formAnswers.forms?.length) {
						const form = formAnswers.forms[0];
						form.fields.forEach((el, index) => {
							el.key = index
						})
						this.autoModeConfig[this.formAuto_index].props.data = form
						this.manualModeConfig[this.formManu_index].props.data = form
					}

					if (formAnswers && formAnswers.answers?.length) {
						let cc = []
						if (this.manualModeConfig[this.formManu_index].props && formAnswers.answers) {
							formAnswers.answers[0].answers.forEach((el, index) => {
								cc[index] = el
							})
						}
						if (cc) this.manualModeConfig[this.formManu_index].props.formAns = cc
						this.updateCandidData()
					}
				}
				else if (soumettre && this.form) {
					let objF = {}
					objF.contact = {}
					if (email) objF.contact.email = email
					if (phone) objF.contact.phone = phone
					if (forms) objF.answers = forms;
					if (this.candidatId) objF.id = this.candidatId
					objF.finish = true
					for (let key in objF) typeof objF[key] === 'string' ? formData.append(key, objF[key]) : formData.append(key, JSON.stringify(objF[key]));
					await this.$service.candidature.submit(this.shortid, formData);
				}
				else if (soumettre && !this.form) {
					for (let key in obj) typeof obj[key] === 'string' ? formData.append(key, obj[key]) : formData.append(key, JSON.stringify(obj[key]));
					await this.$service.candidature.submit(this.shortid, formData);
				}
				this.manualModeConfig[5].props.code = 200
			} catch (e) {
				if (e.response.status !== 200) {
					this.step = 5
					this.manualModeConfig[5].props.code = e.response.status
				}
			}
		},
		prepareCandidateInfo() {
			const getYearMonthDay = function (date) {
				return { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }
			}
			const candidate = {};
			const getpersonalInfo = this.$userData.load('contact', null);
			if (getpersonalInfo.firstName) candidate.firstname = getpersonalInfo.firstName;
			if (getpersonalInfo.lastName) candidate.lastname = getpersonalInfo.lastName;
			if (getpersonalInfo.address || getpersonalInfo.city || getpersonalInfo.country || getpersonalInfo.cp) {
				candidate.location = this.location(getpersonalInfo);
			}
			if (getpersonalInfo.gender) candidate.gender = getpersonalInfo.gender || "";
			if (getpersonalInfo.birthday) candidate.birthday = getpersonalInfo.birthday ? getYearMonthDay(new Date(getpersonalInfo.birthday)) : null;
			if (getpersonalInfo.picture) candidate.photo = getpersonalInfo.picture;
			return candidate;
		},
		location(getpersonalInfo) {
			let loc = {}
			if (getpersonalInfo.address) loc.address = getpersonalInfo.address
			if (getpersonalInfo.city) loc.city = getpersonalInfo.city
			if (getpersonalInfo.country) loc.country = getpersonalInfo.country
			if (getpersonalInfo.cp) loc.cp = getpersonalInfo.cp
			return loc
		},
		isEmptyUserData(user) {
			if (!user?.contact) return true
			else if (!user?.contact.firstName) return true
			else if (!user?.contact.lastName) return true
			else if (!user?.contact?.birthday && this.$siteconf.mandatory_fields?.birthday) return true
			else if (!user?.contact?.gender && this.$siteconf.mandatory_fields?.gender) return true
			else if (!user?.contact?.city && this.$siteconf.mandatory_fields?.city) return true
			else if (!user?.contact?.country && this.$siteconf.mandatory_fields?.country) return true
			else if (!user?.contact?.address && this.$siteconf.mandatory_fields?.address) return true
			else if (!user?.contact?.cp && this.$siteconf.mandatory_fields?.zipcode) return true
			else if (!user?.edu && !user?.file) return true
			else if ((this.$siteconf.contact_choice == 'both' || this.$siteconf.contact_choice == 'phone') && !user.contact.phone) return true
			else return false
		},
		whichModefn(user) {
			if(this.$siteconf?.forceCV) return "Auto"
			if (user.edu && user.edu.length > 0) return "Manuelle"
			else if (user.file && user.file.name && user.file.data) return "Auto"
		},
		//Signin Signup action and save userData and store userEmail connected
		async action(action) {
			try {
				const userData = action === "signin" ? loginData : signupData;
				await this.$service.appservice[action](userData);
				await this.signinSuccess(userData.email, action, userData.phone);
			} catch (error) {
				this.handleError(action);
			}

		},
		handleError(action) {
			const errorProp = action === "signin" ? "signinError" : "signupError";
			this[action + "Config"][0].props[errorProp] = true;
		},
		async signinSuccess(email, action, phone) {
			this.$store.commit('setToken');
			this.$userData({ "email": email, "phone": "" });
			this.$store.commit('setEmail', email);
			if (action == "signin") {
				let user = await this.$service.user.getUser();
				if (user) {
					this.$utils.updateUserStorage(user)

					const contactInfo = {
						email
					};

					const dataByEmail = this.$userData(contactInfo, "data", null);
					this.isEmpty = this.isEmptyUserData(dataByEmail[0])
					if (!this.isEmpty) {
						this.whichMode = this.whichModefn(dataByEmail[0])
						this.config = this.candidModeConfig
						this.step = 0
					}
					else {
						this.config = this.candidModeConfig
						this.step = 1
					}
				}

			} else {
				this.step = 1;
				this.config = this.candidModeConfig;
				const contactInfo = {
					email, phone
				};
				this.manualModeConfig[0].props.data.phone = phone;
				this.autoModeConfig[1].props.data.phone = phone;

				this.getCandidId(contactInfo)
			}

		},
		async getForm() {
			try {
				this.dynForm = await this.$service.candidature.getForm(this.$route.params.offerId)
				// dynForm = [{
				// 	"id": "ktJe6iSRZHdSE6jBm", "fields": [
				// 		{ "type": "long", "mandatory": true, "label": "bbbbbbbbb", "targetComment": true }, {
				// 			"type": "date", "mandatory": true, "label":
				// 				"cccccccccc", "targetComment": true
				// 		}, {
				// 			"type": "range", "mandatory": true, "label": "ddddddddd", "values": { "min": 5, "max": 555 },
				// 			"targetComment": true
				// 		}, { "type": "select1", "mandatory": true, "label": "eeeeeee", "values": ["a", "b", "c", "d"], "targetComment": true },
				// 		{ "type": "selectn", "mandatory": true, "label": "ffffffffffff", "values": ["a", "b", "c", "d", "e", "f"], "targetComment": true },
				// 	], "name": "cccccccccc", "mandatory": false
				// }]

				this.form = this.dynForm && this.dynForm.length > 0;

				this.manualModeConfig[this.sk_index].props.form = this.form
				this.manualModeConfig[this.edu_index].props.form = this.form
				this.autoModeConfig[1].props.form = this.form
				this.manualModeConfig[4].props.form = this.form
				this.cooptationlModeConfig[0].props.form = this.form

				if (this.form) {
					const firstForm = this.dynForm[0];
					if (firstForm) {
						firstForm.fields.forEach((el, index) => {
							el.key = index
						})
						this.autoModeConfig[this.formAuto_index].props.data = this.dynForm[0]
						this.manualModeConfig[this.formManu_index].props.data = this.dynForm[0]
						this.cooptationlModeConfig[0].props.data = this.dynForm[0]
					}
				}
			} catch (error) {
				console.log("error while getting form");
			}
		},
		reset(text) {
			signupData.password = ""
			signupData.rgpd_consent = false
			loginData.password = ""
			this.signinConfig[0].props.signinError = false
			this.signupConfig[0].props.signupError = false
			autoInfo.file = null;
			if (text == "done") {
				this.$router.push({
					name: ROUTES_NAME.OFFERS,
					params: this.$toMove?.search?.params ?? {},
					query: this.$toMove?.search?.query ?? {}
				});
			}
			if (this.$siteconf.cooptation) {
				this.$userData.clear();
				contactInfo.email = ""
				contactInfo.phone = ""
				this.cooptationlModeConfig[0].props.formAns = []
				dynamicForm = []

			}
			this.$pref.candidatId= null
			this.candidatId=""
		},
		async getCandidId(obj) {
			if (this.candidatId && !this.$siteconf.cooptation) return;
			else if (this.$siteconf.cooptation && this.$utils.equals(contactInfo, this.contactInfosend)) return;
			let obj1 = {}
			if (!obj) {
				if (contactInfo.email) { obj1.email = contactInfo.email; }
				if (contactInfo.phone) obj1.phone = contactInfo.phone
				let forms = []
				forms = this.prepareFormCoopAns()
				if (forms.length) obj1.answers = forms
			}
			try {
				let candidatData = obj ? await this.$service.candidature.submitManual(this.shortid, obj) :
					await this.$service.candidature.submitManual(this.shortid, obj1)
				if (candidatData) {
					this.candidatId = candidatData
					this.autoModeConfig[0].props.candidatId = candidatData
					this.cooptationlModeConfig[3].props.candidatId = candidatData
					this.$pref.candidatId=candidatData
				}
				this.contactInfosend = JSON.parse(JSON.stringify(contactInfo));
			} catch (error) {
				//console.log("erro");
			}

		},
		async sendCooptation() {
			const candidate = this.prepareCandidateInfo();
			const formdata = new FormData();
			const forms = this.prepareFormCoopAns(formdata);
			if(!this.candidatId) this.candidatId = this.$pref.candidatId ? this.$pref.candidatId :  this.getCandidId()
			let objF = {}
			objF.contact = {}
			objF.contact.email = contactInfo.email
			objF.contact.phone = contactInfo.phone
			objF.answers = forms;
			if (this.candidatId) objF.id = this.candidatId
			objF.info = candidate
			objF.finish = true;
			for (let key in objF) typeof objF[key] === 'string' ? formdata.append(key, objF[key]) : formdata.append(key, JSON.stringify(objF[key]));
			try {
				await this.$service.candidature.submit(this.shortid, formdata);
				this.step++
				this.cooptationlModeConfig[3].props.code = 200
			} catch (e) {
				if (e.response.status !== 200) {
					this.step = 4
					this.cooptationlModeConfig[3].props.code = e.response.status
				}
			}

			this.$userData.clear()
			contactInfo.email = ""
			contactInfo.phone = ""
			this.cooptationlModeConfig[0].props.formAns = []
			dynamicForm = []
		},
		saveCooptationAns(ans) {
			if (ans) this.formCoopAns = ans
			this.cooptationlModeConfig[0].props.formAns = ans
			//this.$userData.clear()
		},
		updateCandidData() {
			//	this.$userData.merge(contactInfo, null, { phone: '', email: '' });
			this.$userData.merge(personalInfo, null, {
				firstName: "",
				lastName: "",
				birthday: null,
				email: "",
				phone: "",
				address: "",
				state: "",
				country: "france",
				cp: "",
				picture: "",
				gender: "undisclosed"

			});

			const exp = this.$userData(null, 'exp') ||
				[JSON.parse(JSON.stringify(defaultExperience))];
			this.manualModeConfig[this.exp_index].props.data = exp; // Why ?
			this.manualModeConfig[4].props.data.experience = exp;	// Why ?
			const edu = this.$userData(null, 'edu') ||
				[JSON.parse(JSON.stringify(defaultEducation))];
			this.manualModeConfig[this.edu_index].props.data = edu;	// Why ?
			this.manualModeConfig[4].props.data.education = edu;	// Why ?
			//const skills = {};
			this.$userData.merge(skills, 'skills', {
				selectedTechs: [],
				selectedLangs: [],
				selectedSkills: []
			});
			this.manualModeConfig[this.sk_index].props.data = skills;

			if (this.form && !this.$siteconf.cooptation) {
				const formId = this.manualModeConfig[this.formManu_index].props.data.id;
				if (formId) {
					const saved = this.$userData(null, formId) || [];
					if (Array.isArray(saved)) {
						const dataMan = saved.map((el, index) => el || this.manualModeConfig[this.formManu_index].props.formAns[index]);
						const dataAuto = saved.map((el, index) => el || this.autoModeConfig[this.formAuto_index].props.formAns[index]);
						this.manualModeConfig[this.formManu_index].props.formAns = dataMan;
						this.autoModeConfig[this.formAuto_index].props.formAns = dataAuto;
					}
				}
			}
			const file = this.$userData(null, 'file');
			if (file) autoInfo.file = file;
		},
		async verifCandidat(type) {
			if (type == "connected") {
				const email = this.$store.state.email;
				const contactInfo = {
					email
				};
				const dataByEmail = this.$userData(contactInfo, "data", null);
				if (!dataByEmail || !dataByEmail[0].data) this.$userData({ email, "phone": "" });

				this.isEmpty = this.isEmptyUserData(dataByEmail[0])

				this.getCandidId(contactInfo)
				if (!this.isEmpty) {
					this.whichMode = this.whichModefn(dataByEmail[0])
				}
				else this.step++
			}
			else {
				let obj = {}
				if (contactInfo.email) { obj.email = contactInfo.email; }
				if (contactInfo.phone) obj.phone = contactInfo.phone
				if ((obj.email || obj.phone) && type != "signup") {
					this.getCandidId(obj)
					//this.step++
				}
				if (!this.$siteconf.accounts || type == "sansCompte") {

					this.manualModeConfig[0].props.data.phone = contactInfo.phone;
					this.autoModeConfig[1].props.data.phone = contactInfo.phone;
					this.cooptationlModeConfig[2].props.data.phone = contactInfo.phone;
					this.step++
				}
				else {
					switch (type) {
						case "signin":
							this.config = this.signinConfig;
							this.step = null;
							break;
						case "signup":
							this.config = this.signupConfig;
							this.step = null;
							break;
						case "modif":
							this.config = this.candidModeConfig;
							this.step++;
							break;
						case "sendCandidature":
							this.config = this.whichMode === "Manuelle" ? this.manualModeConfig : this.autoModeConfig;
							if (this.form) {
								await this.whichMode === "Manuelle" ? this.soumettreManuelle(false) : this.soumettreAuto(false)

								let id = null
								if (this.dynForm) id = this.dynForm[0].id;
								if (!id) return;
								this.formdata = this.$userData.load(id, this.formAns);
								this.step = this.whichMode === "Manuelle" ? 4 : 1;
							} else {
								this.whichMode === "Manuelle" ? this.soumettreManuelle(true) : this.soumettreAuto(true)
								this.step = this.whichMode === "Manuelle" ? 5 : 3;
							}
							break;
					}

					this.signinConfig[0].props.datalogin.email = contactInfo.email
					this.signupConfig[0].props.data.email = contactInfo.email
					this.signupConfig[0].props.data.phone = contactInfo.phone
				}
			}

		},
		uploadPrev() {
			this.config = this.candidModeConfig
			this.step = 1
		},
		prepareFormCoopAns(formData) {
			let forms = []
			const formFields = this.cooptationlModeConfig[0].props.data.fields;
			const formAnswers = this.formCoopAns;
			if (formAnswers && formFields) {
				const uniqFile = {};
				const objAns = {
					name: this.manualModeConfig[this.formManu_index].props.data.name,
					id: this.manualModeConfig[this.formManu_index].props.data.id,
					answers: formFields.map((field, index) => {
						const answer = formAnswers[index];
						if (!answer) return undefined;
						if (field.type === "date") return this.$utils.isDate(answer);
						if (!formData && ['attachment', 'cv'].includes(field.type)) return undefined;
						if (field.type === "attachment") {
							if (!uniqFile[answer.hash]) {
								formData.append(answer.hash, answer.file);
								uniqFile[answer.hash] = true;
							}
							return answer.hash;
						}
						if (field.type === "cv") return undefined;
						return answer;
					})
				};
				forms.push(objAns);
			}
			return forms

		}
	},
	async mounted() {
		this.$userData.merge(contactInfo, null, { phone: '', email: '' });
		this.job_title = this.title
		this.configs = {
			mode: this.candidModeConfig,
			signin: this.signinConfig,
			signup: this.signupConfig,
			//"signup-info": this.signupInfoConfig,
			manual: this.manualModeConfig,
			auto: this.autoModeConfig,
		};

		this.getForm();
		//this.config=this.cooptationlModeConfig
		if (this.$siteconf.cooptation) this.config = this.cooptationlModeConfig

		this.selectConfig();

		const findIndexByStep = (configArray, step) => {
			return configArray.findIndex(object => object.step === step);
		};


		this.formAuto_index = findIndexByStep(this.autoModeConfig, "form");
		this.formManu_index = findIndexByStep(this.manualModeConfig, "form");
		this.exp_index = findIndexByStep(this.manualModeConfig, "experience");
		this.edu_index = findIndexByStep(this.manualModeConfig, "education");
		this.sk_index = findIndexByStep(this.manualModeConfig, "skills");

		this.shortid = this.$toMove.shortId || this.$route.params.offerId;
	},


};

</script>
<style lang="scss" scoped>
.candidate-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1002;
	width: 100%;
	height: 100%;
	transform: scale(0);
	transition: 0.2s;
	border-radius: 10px;

	&.show {
		transform: scale(1);
	}
}

.dynamic-form {
	//max-width: 800px;
	margin: auto;
}

.candidate-form-content {
	background: white;
	border-radius: var(--border-radius);
	overflow: auto;
	max-height: 100%;

	@media (max-width: 991px) {
		height: 100% !important;
	}
}


.empty {
	background-color: transparent !important;
	border-color: var(--primary-color) !important;
	color: var(--primary-color);
	cursor: default
}

.mdc-chip {
	background-color: var(--primary-color);
}

.steps {
	position: absolute;
	width: fit-content;
	right: 0;
	top: -4rem;
	margin-right: -15%;

	&::after {
		background-color: red;

	}

	@media (max-width: 991px) {
		left: 5px;
		top: 10px;
		margin-right: -15%;
		z-index: 100;
		background-color: white !important;
	}
}

.validate {
	height: 435px !important;
}
</style>
