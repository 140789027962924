<template lang="">
	<div class="">
		<div
			class="d-flex px-0 px-lg-5 py-1 bg-white border-3 border d-none d-lg-flex position-fixed w-100 hide-on-print"
			style="z-index: 1;"
		>
			<router-link
				:to="offerRoute"
				class="underline-none"
			>
				<MatButton
					class="conditionLink"
					:label=" $t(`resultat`)"
					icon="arrow_back"
				/>
			</router-link>
			<div class="flex-max" />
			<div
				class="d-flex"
				v-if="offerTotNmb>1"
			>
				<router-link
					:class="{'defautCur':prDis}"
					:to="offerPrevRoute"
					class="underline-none"
				>
					<MatButton 
						class="d-none d-lg-block conditionLink"
						:disabled="prDis"
						:label="$t(`pre`)"
						icon="arrow_back_ios_new"
						@click="prevOff"
					/>
				</router-link>
			
				<MatIconButton
					mini
					class="rotate mt-2 defautCur d-none d-lg-block"
					icon="horizontal_rule"
				/>
				<router-link
					:to="offerSuivRoute"
					class="underline-none"
					:class="{'defautCur':svDis}"
				>
					<MatButton
						class="d-none d-lg-block conditionLink"
						:label="$t(`suiv`)"
						icon="arrow_forward_ios"
						trailingIcon
						iconPosition="end"
						@click="nextOff"
						:disabled="svDis"
					/>
				</router-link>
			</div>
		</div>
		<div
			class="offers-detail-header overflow-hidden d-none d-lg-block show-on-print"
			
			:style="{
				'background-color': !(offer.banner || $siteconf?.banner) ? 'var(--primary-color)' : ''
			}"
		>
			<img
				v-if="offer.banner || $siteconf?.banner"
				:src="offer.banner ? '/c/images/offer'+offer.banner: '/c/banner.jpg'"
				class="banner-img hide-on-print "
				onerror="this.src= '/c/banner.jpg'"
			>
			<div
				class="blur-background hide-on-print"
				v-if="$siteconf?.banner"
			/>
			<div
				class="position-relative header-content flex-column container-xxl "
				style="margin-top: 45px;"
			>
				<div class="d-none d-lg-block show-on-print">
					<h1
						class="h3 titleblur"
						style="text-align:center"
					>
						{{ offer.job_title }}
					</h1>
					<h6 class="fw-medium text-center ">
						<span class="titleblur">
							{{ offer.company }}
						</span>
					</h6>
					<div class="flex-horizontal-center">
						<h6 v-if="offer.location">
							<span class="titleblur">{{ offer?.location }}</span>
						</h6>
						<span
							class=""
							v-if="offer.location && offer.contract "
						>|</span>
						<h6 v-if="offer.contract ">
							<span class="titleblur">{{ getContract }}</span>
						</h6>
						<span
							class=""
							v-if=" offer.contract && offer.salary"
						>|</span>
						<h6 v-if="offer.salary">
							<span class="titleblur">{{ salary }}</span>
						</h6>
					</div>
					<div class="position-absolute d-flex headerButton hide-on-print">
						<MatIconButton
							ref="print_btn"
							mini
							icon="print"
							class="color me-2 small mt-1 d-none d-lg-block"
							@click="print"
						/>
						<MatIconButton
							ref="share_btn"
							mini
							icon="share"
							class="color me-2 small mt-1"
							@click="share"
						/>
						<router-link :to="{name:ROUTES_NAME.POST, params:$route.params}">
							<MatButton
								:label="applyButton"
								raised 
								cta
								class="mt-0"
							/>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<span class="d-block d-lg-none hide-on-print">
			<div
				class="offers-detail-header overflow-hidden "
				v-if="scrollpx<20"
				:style="{
					'background-color': !(offer.banner || $siteconf?.banner) ? 'var(--light-background)' : ''
				}"
			>
				<img
					v-if="offer.banner || $siteconf?.banner"
					:src="offer.banner ? '/c/images/offer'+offer.banner: '/c/banner.jpg'"
					class="banner-img"
					onerror="this.src= '/c/banner.jpg'"
				>
				<div
					class="blur-background"
					v-if="$siteconf?.banner"
				/>
				<div class="position-relative header-content flex-column container-xxl ">
					<headerContainer
						:title="offer.job_title"
						class="w-100"
					>

						<div
							class="d-flex w-100 "
						>
							<router-link :to="offerRoute">
								<MatButton
									:label=" $t(`resultat`)"
									icon="arrow_back"
								/></router-link>
							
							<div class="flex-max" />
							<div
								class="d-flex"
								v-if="offerTotNmb>1"
							>
								<router-link
									:to="offerPrevRoute"
									:class="{'defautCur':prDis}"
								>
									<MatButton 
										class="d-block d-lg-none ps-5"
										:disabled="prDis"
										icon="arrow_back_ios_new"
										@click="prevOff"
									/>
								</router-link>
							
								<router-link
									:to="offerSuivRoute"
									:class="{'defautCur':svDis}"
								>
									<MatButton
										class="d-block d-lg-none"
										icon="arrow_forward_ios"
										trailingIcon
										iconPosition="end"
										@click="nextOff"
										:disabled="svDis"
									/>
								</router-link>
							</div>
							
							
						</div>
				
					</headerContainer>
				</div>
			</div>
			<headerContainer
				:title="offer"
				type="nobg"
				v-if="scrollpx>=20"
				class=" position-fixed w-100 "
			>
				<div
					class="d-flex px-0 px-lg-5 py-1 bg-white test w-100 border-3 border-bottom border-top container-xxl"
				>
					<router-link :to="offerRoute">
						<MatButton
							:label=" $t(`resultat`)"
							icon="arrow_back"
						/>
					</router-link>
					
					<div class="flex-max" />
					<div
						class="d-flex"
						v-if="offerTotNmb>1"
					>

						<router-link
							:to="offerPrevRoute"
							:class="{'defautCur':prDis}"
						>
							<MatButton 
								class="d-block d-lg-none ps-5"
								:disabled="prDis"
								icon="arrow_back_ios_new"
								@click="prevOff"
							/>
						</router-link>
						<router-link
							:to="offerSuivRoute"
							:class="{'defautCur':svDis}"
						>
							<MatButton
								class="d-block d-lg-none"
								icon="arrow_forward_ios"
								trailingIcon
								iconPosition="end"
								@click="nextOff"
								:disabled="svDis"
							/></router-link>
					</div>
				
				</div>
			
			</headerContainer>

		</span>
		
		<span>
			
			<div
				class="bg-white container allData"
			> 
				<div class="row pe-0 pe-lg-5">
					<div class="px-5 pt-3 d-none d-lg-block col-3 background leftMenu">
						<section class="p-3 spaced noborder">
							<div
								class="flex-vertical-center justify-content-center flex-wrap flex-column "
								v-if="offer.company"
							>
								<div style="max-width: 200px;    max-height: 100px;">
									<img
										v-if="$siteconf.logo"
										class="pb-3"
										:src="offer.company_logo"
										style="max-height: 100px; max-width: 100%;"
									>
								</div>
								
								<h6>{{ offer?.company }}</h6>
							</div>
							<h6 class="ps-3">
								{{ offer.job_title }}
							</h6>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.date"
							>
								<span class="material-icons-outlined fs-5 me-2">
									schedule
								</span> {{ $t(`Publiée`) }} : {{ date }}
							</div>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.contract"
							>
								<span class="material-icons-outlined fs-5 me-2">
									description
								</span>
								{{ getContract }}
							</div>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.location"
							>
								<span class="material-icons fs-5 me-2">
									place
								</span>
								{{ offer.location }}
							</div>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.edu_lvl"
							>
								<span class="material-icons fs-5 me-2">
									school
								</span>
								{{ $t('education.'+offer?.edu_lvl) }}
							</div>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.exp_lvl"
							>
								<span class="material-icons-outlined fs-5 me-2">
									work
								</span>
								>{{ offer.exp_lvl }} {{ $t(`ans`) }}
							</div>
							<div
								class="body1 flex-vertical-center"
								v-if="offer.salary"
							>
								<span class="material-icons-outlined fs-5 me-2">
									payments
								</span>
								{{ salary }}
							</div>
							<div class="pt-3 text-center hide-on-print">
								<router-link :to="{name:ROUTES_NAME.POST, params:$route.params}">
									<MatButton
										:label="applyButton"
										raised
										cta
										class="w-100"
									/>
								</router-link>
							</div>
						</section>
					</div>
					<div class="col col-lg-9 position-relative descPrint">
						<div
							v-if="scrollpx > 380"
							class="text-end pe-3 pt-2 sticky-sm-top d-none "
						>
							<MatIconButton
								ref="remove_btn"
								mini
								disabled
								icon="bookmark_border"
								class="color me-2 d-none "
							/><MatIconButton
								ref="remove_btn"
								mini
								icon="share"
								class="color me-2"
								@click="share"
							/>
						</div>
						<section
							class="p-3 spaced-4 noborder sectionBotton"
							:class="{'mrTop': scrollpx > 20}"
						>
							<div
								class="spaced "
								v-if="offer.description && offer.description.company"
							>
								<h2 class="h6"> {{ companyDescription }} </h2>
								<div
									class="htmlDesc"
									v-html="offer.description.company"
								/>
							</div>
							<div
								class="spaced"
								v-if="offer.description && offer.description.job"
							>
								<h2 class="h6">{{ jobDescription }} </h2>
								<div
									class="htmlDesc"
									v-html="offer.description.job"
								/>
							</div>
					
							<div
								class="spaced"
								v-if="offer.description && offer.description.candidate"
							>
								<h2 class="h6"> {{ candidateDescription }}</h2>
								<div
									class="htmlDesc"
									v-html="offer.description.candidate"
								/>
							</div>		
							<div class="mt-5 text-end d-none d-lg-block hide-on-print">
								<router-link
									:to="{name:ROUTES_NAME.POST, params:$route.params}"
								>
									<MatButton
										raised
										cta
										:label="applyButton"
									/>
								</router-link>
							</div>	
						</section>
						<div class="d-flex d-lg-none indexButton pb-3 hide-on-print">
								
							<MatFabButton
								class="ms-3 "
								icon="share"
								position="static"
								:actions="actions"
							/>
							<div class="flex-max" />
							<router-link
								class=""
								:to="{name:ROUTES_NAME.POST, params:$route.params}"
							>
								<MatButton
									raised
									cta
									:label="applyButton"
								/>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<CandidateForm
				:class="{show: showCandidateContainer}"
				:offer="offer"
				:title="offer.job_title"
			/>
		</span>
	</div>
</template>
<script>
import { ROUTES_NAME } from "@/router"
import CandidateForm from "./candidate-form.vue"
import headerContainer from "@/components/application/header-caintainer.vue"
import { EducationLevel, Contract } from "@/utils/constants";
import MatFabButton from "@/components/material/mat-fab-button.vue";
import { shortText, pageTitle } from "@/utils/utils";

export default {
	name: "OfferDetailsPage",
	components: {
		CandidateForm,
		headerContainer,
		MatFabButton,
	},
	data() {
		return {
			applyButton: (this.$siteconf & this.$siteconf.applyButton) || (this.$t && this.$t('jPostule')) || '',
			scrollpx: 0,
			ROUTES_NAME,
			showCandidateContainer: false,
			offer: {},
			EducationLevel,
			Contract,
			totalPage: 0,
			svDis: false,
			prDis: false,
			firstpage: true,
			lastpage: false,
			offerTotNmb: 0,
			actions: null,
			offerRoute: { name: ROUTES_NAME.OFFERS },
			offerSuivRoute: {},
			offerPrevRoute: {}
		}
	},
	computed: {
		education() {
			return EducationLevel[this.offer.edu_lvl];
		},
		job_title() {
			return this.offer.job_title;
		},
		getContract() {
			const contracts = this.offer?.contract ? this.offer.contract.split(', ') : [];
			if (contracts.length) {
				return contracts.map(c => this.$t('contract.' + c)).join(', ')
			}
			return '';
		},
		companyDescription() {
			return this.$siteconf?.title?.description?.company ?? this.$t('envi');
		},
		jobDescription() {
			return this.$siteconf?.title?.description?.job ?? this.$t('poste');
		},
		candidateDescription() {
			return this.$siteconf?.title?.description?.candidate ?? this.$t('profil');
		},
		salary() {
			let salary = this.offer.salary
			let salaryText = ""
			if (salary) {
				if (!salary.min && !salary.max) return ""
				if (salary.min && salary.max && salary.min == salary.max) salaryText += salary.min
				else if (salary.min && salary.max) salaryText += salary.min + "-" + salary.max
				else if (salary.min) salaryText += salary.min
				else if (salary.max) salaryText += salary.max

				salaryText += salary.currency === 'EUR' || !salary.currency ? '€' : '$';

				if (salary.period) salaryText += "/" + this.$t(this.offer.salary.period)

				return salaryText
			}
			else return salaryText
		}
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		this.position();
		this.updateOfferRoute()
		if (this.offerTotNmb > 1) {
			this.redirectOfferSuiv();
			this.redirectOfferPrev()
		}
		this.applyButton = this.$siteconf.applyButton || this.$t('jPostule');
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	watch: {
		$route: {
			handler(newVal, oldVal) {
				if (this.$route.name == ROUTES_NAME.POST) {
					this.showCandidateContainer = true
					// !this.$ssr && (document.querySelector("html").style.overflow = "hidden")

				} else {
					this.showCandidateContainer = false
					//!this.$ssr && (document.querySelector("html").style.overflow = "auto")


				}
				if (newVal.name == oldVal?.name) {
					this.init()
					this.redirectOfferSuiv();
					this.redirectOfferPrev()
				}
				this.actions = [
					{
						icon: "fa-brands fa-facebook-f text-primary",
						onClick: () => {
							this.share(event, "fb");
						},
					},
					{
						icon: "fa-brands fa-linkedin text-primary",
						onClick: () => {
							this.share(event, "linkedin");
						},
					},
					{
						icon: "fa-brands fa-x-twitter text-primary ",
						onClick: () => {
							this.share(event, "twitter");
						},
					},
				];
			},
			immediate: true
		}
	},
	methods: {
		handleScroll() {
			this.scrollpx = window.scrollY;
		},
		async init() {
			if (this.$route.params.offerId != 0) {
				this.offer = await this.$service.offer.findOne(this.$route.params.offerId);
				this.date = new Date(this.offer.date).toLocaleDateString("fr")
				if (this.offer.description) this.offer.description = this.$utils.changeTags(this.offer.description);
				// TODO: use offer image in og:image
				this.$useHead({
					title: pageTitle(this, this.offer.job_title), meta: {
						name: "description", content: shortText(this.offer.description.job?.replace(/<[^>]+>/g, ''), 160)
					}
				})
			}
		},
		position() {
			this.$cache?.nbrOff ? this.offerTotNmb = this.$cache?.nbrOff : this.$cache?.shortIdList ? this.offerTotNmb = this.$cache?.shortIdList?.length : 0;
			if (this.offerTotNmb > 1) {
				this.totalPage = Math.ceil(this.offerTotNmb / 12)
				if (!this.$toMove.search.query.page || this.$toMove.search.query.page == 1) this.firstpage = true
				else this.firstpage = false
				if (this.$toMove.search.query.page == this.totalPage) this.lastpage = true
				else if (this.totalPage == 1 && (this.$toMove.search.query.page == 1 || !this.$toMove.search.query.page)) this.lastpage = true
				else this.lastpage = false
				let index = this.$cache?.shortIdList.findIndex(object => object.shortid === this.$toMove.shortId);
				if (this.firstpage && index == 0) this.prDis = true
				if (this.lastpage && index == this.$cache.shortIdList.length - 1) this.svDis = true
			}

		},
		share(e, social) {
			if (e) e.preventDefault()
			if (!social) social = "fb"
			let url = this.url.href;
			this.$utils.share(social, url)

		},

		updateOfferRoute() {
			this.offerRoute = {
				name: ROUTES_NAME.OFFERS,
				params: this.$toMove?.search?.params,
				query: this.$toMove?.search?.query
			};
		},
		///next Off
		redirectOfferSuiv() {
			const list = this.$cache?.shortIdList || [];
			const id = parseInt(this.$route.params.offerId);
			let index = list.findIndex(object => object.shortid == id);
			if (index < 0) return {};
			if (index < list.length - 1) ++index;
			this.offerSuivRoute = {
				name: ROUTES_NAME.OFFER_DETAILS,
				params: {
					offerId: list[index]?.shortid,
					name: this.toURL(list[index]?.title)
				}
			}
		},
		async nextOff() {
			const list = this.$cache?.shortIdList || [];
			this.prDis = false;
			let countIds = list.length

			if (countIds < this.$cache.nbrOff) {
				this.getAllOff()
			}
			let index = list.findIndex(object => {
				return object.shortid === this.$toMove?.shortId;
			});
			let page = this.$toMove?.search?.query?.page || 1
			if (index > page * 12 - 2) {
				this.$toMove.search = {
					"params": this.$toMove.search.params,
					"query": { ...this.$toMove.search.query, "page": page + 1 }
				}
				this.updateOfferRoute();
			}
			if (index < countIds - 1)
				this.$toMove.shortId = list[index + 1].shortid
			this.position()
		},
		//Prev Off

		redirectOfferPrev() {
			const list = this.$cache?.shortIdList || [];
			const id = parseInt(this.$route.params.offerId)
			let index = list.findIndex(object => object.shortid == id);
			if (index == -1) return {}
			if (index > 0) --index;
			this.offerPrevRoute = {
				name: ROUTES_NAME.OFFER_DETAILS,
				params: {
					offerId: list[index].shortid,
					name: list[index].title
				}
			}
		},
		async prevOff() {
			const list = this.$cache?.shortIdList || [];
			this.svDis = false;
			let countIds = list.length

			if (countIds < this.$cache.nbrOff) {
				this.getAllOff()
			}
			let index = list.findIndex(object => object.shortid === this.$toMove?.shortId);
			let page = this.$toMove?.search?.query?.page || 1
			if (index < (page - 1) * 12) {
				this.$toMove.search = {
					"params": this.$toMove.search.params,
					"query": { ...this.$toMove.search.query, "page": page - 1 }
				}
				this.updateOfferRoute();
			}
			if (index > 0) this.$toMove.shortId = list[index - 1]?.shortid
			this.position()
		},
		async getAllOff() {
			let offers = await this.$service.offer.findAll({ "n": this.$cache.nbrOff, "s": 0 });
			this.$cache.shortIdList = offers.offers.map(x => {
				let item = { "shortid": x.short_id, "id": x._id, "title": x.job_title }
				return item
			}
			)

		},
		print(){
			window.print();
		}


	},
	async beforeMount() { this.init() },
	async serverPrefetch() { await this.init() }
};
</script>
<style lang="scss" scoped>
.offers-detail-header {
	//	display: block ;
	position: relative;
	background-size: cover;

	* {
		color: white;
	}
}

.background {
	background: var(--light-background);
}

.padding {
	padding-top: 70px !important;
}

.color {
	background: rgba(255, 255, 255, 0.4);
	border-radius: 30%;
	backdrop-filter: blur(10px);
}

.headerButton {
	bottom: 20px;
	right: 20px;
}

.mdc-icon-button.mini.small {
	--mdc-icon-button-size: 30px;
	--mdc-icon-size: 15px;
}

.rotate {
	transform: rotate(90deg);
	color: var(--primary-color);
}

.mdc-icon-button.mini.rotate {
	--mdc-icon-button-size: 20px;
	--mdc-icon-size: 20px;
}

.smTitle {
	top: 15px;
	left: 100px;
	// position: absolute;
}

.test {
	//	height: 90px;
	// top: 75px;
}

.blur {
	//position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.4);
	filter: blur(2px);

	border-radius: 20px;
}

.containerbg {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 4.65%, rgba(0, 0, 0, 0.3) 91.51%);
}

::v-deep(.htmlDesc) {
	line-height: 1.25rem;

	h3,
	h4,
	h5,
	h6 {
		font-size: 1.4rem;
		font-weight: 400;
		line-height: 2.75rem;
		letter-spacing: 0px;
	}

	p {
		margin-top: 1em;
	}
}

.index {
	z-index: 10;
}

.indexButton {
	position: sticky;
	bottom: 0;
}

.conditionLink {
	color: var(--primary-25) !important;

	&:hover {
		color: var(--link-active) !important;
		text-decoration: none !important;
	}

}

.router-link-exact-active {
	// border-bottom: 2px solid var(--link-active);
	color: var(--link-active) !important;
	text-decoration: none;

	&.underline-none {
		border: none;
	}
}

.banner-img {
	position: absolute;
	bottom: 0;
	height: 100%;
	object-fit: cover;
	width: 100%;
}
.mrTop {
	@media(max-width:991px) {
		margin-top: 200px;
	}
}
@media print {
    .hide-on-print {
        display: none !important;
    }
	.leftMenu{
		padding: 0 !important;
		display: flex !important;
		flex: 0 0 auto!important;
		width: 25% !important;
        background-color: white !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
	}
	.descPrint{
		padding: 0 !important;
		flex: 0 0 auto!important;
        width: 75%!important;
	}
	.mrTop {
		margin: 0 !important;
	}
	.allData{
		margin: 0;
		padding: 0;
		max-width: 100%!important;
	}
	.show-on-print{
		display: block !important;
	}
    .blur-background{
		background: none !important; 
    }
	.header-content {
		height: auto !important;
		margin-top: 0 !important;
	}
	.banner-img {
		object-fit: fill;
	}
	.titleblur{
		text-shadow: none !important;
		color: black !important;
	}
}
</style>